import React from 'react';

import SectionHeading from './SectionHeading';
import SubHeading from './SubHeading';
import Columns from './Columns';
import List from './List';

const Services = () => (
  <div className="services">
    <SectionHeading>Services</SectionHeading>

    <p>
      I have a passion for creating beautifully designed software experiences
      that connect businesses and their customers. I have over 10 years of
      experience designing, developing, and scaling applications that have been
      used by millions of people around the world.
    </p>

    <p>
      User experience is at the core of everything I do. From the design
      research, to the user interface, to the technical implementation— it needs
      to be simple, clear, and easy to use. In a world saturated with competing
      software products that are similar, you win by creating something that is
      simple to understand, reliable, and delightful to use.
    </p>

    <Columns>
      <div>
        <SubHeading>Design</SubHeading>
        <List>
          <li>Ideation + planning</li>
          <li>Wireframes</li>
          <li>Prototyping</li>
          <li>User testing</li>
          <li>User research</li>
          <li>Interface design</li>
          <li>Branding</li>
        </List>
      </div>
      <div>
        <SubHeading>Development</SubHeading>
        <List>
          <li>Technical architecture design</li>
          <li>Single-page applications (SPAs)</li>
          <li>Progressive web apps (PWAs)</li>
          <li>Pattern libraries</li>
          <li>Component-driven architectures</li>
          <li>E-commerce sites</li>
          <li>Accessible implementations (WCAG 2.1)</li>
        </List>
      </div>
      <div>
        <SubHeading>Misc.</SubHeading>
        <List>
          <li>Team trainings</li>
          <li>Speaking engagements</li>
          <li>Motion design</li>
          <li>Video production</li>
        </List>
      </div>
    </Columns>
  </div>
);

export default Services;
