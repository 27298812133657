import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import Services from '../components/Services';

const ServicesPage = ({ location }) => (
  <Layout currentPath={location.pathname} scroll>
    <SEO
      title="Brian Kulp Co. | Services"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />
    <Services />
  </Layout>
);

export default ServicesPage;
