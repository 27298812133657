import React from 'react';

const style = {
  fontSize: 16,
  fontWeight: 800,
  textTransform: 'uppercase'
};

const SubHeading = ({ children }) => <h4 style={style}>{children}</h4>;

export default SubHeading;
